import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout/layout.jsx";
import config from "../../data/SiteConfig";
import Link from "gatsby-link";
import { Container, Heading, Button, Section } from 'react-bulma-components';


class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Not found | {config.siteTitle}</title>
        </Helmet>
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Heading>Sorry – that page has not been found</Heading>
            <p className="subtitle">(Error 404)</p>
            <hr />
            <Link to="/">
            <Button color="primary">&larr; Start again at the home page</Button></Link>
          </Container>
        </Section>
      </Layout>
    );
  }
}

export default NotFoundPage;
